






























































































import { Component, Vue } from 'vue-property-decorator'
import { Validator, ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectStoreService from '@/services/SelectStoreService'
import Footer from '@/components/Footer.vue'
import Logo from '@/components/Logo.vue'
import CheckDevice from '../helpers/CheckDevice'
import LayoutCompactFooterOnly from '@/layouts/LayoutCompactFooterOnly.vue'

const dict = {
  custom: {
    Zipcode: {
      max: () => '郵便番号を7文字以下で入力してください。',
      regex: () => '郵便番号を数値で入力してください。'
    }
  }
}
Validator.localize('jp', dict)

@Component({
  components: {
    Footer,
    ValidationProvider,
    ValidationObserver,
    Logo,
    LayoutCompactFooterOnly
  }
})
export default class SelectStore extends Vue {
  public pattern: string = `^(\\d)+$`
  public messageError: boolean = false

  private zip_code: string = ''
  private isMobile: boolean = false
  private zipcodeFirst: string = ''
  private zipcodeLast: string = ''

  created() {
    if (CheckDevice.isMobile()) {
      this.isMobile = true
    }
  }

  public async handleSearch(): Promise<void> {
    if (this.isMobile) {
      this.messageError = false
      if (this.zip_code == '') {
        this.messageError = true
      } else {
        await SelectStoreService.selectStore(this.zip_code).then(
          (response: any) => {
            if (response.data.items.length !== 0) {
              localStorage.setItem('items', JSON.stringify(response.data.items))
              this.$router.push({
                name: 'select-store-success'
              })
            } else {
              this.$router.push({ name: 'select-store-error' })
            }
          },
          (error: any) => {
            if (error.response.status === 429) {
              localStorage.clear()
              this.$bvModal.show(`too-many-request`)
            }
          }
        )
      }
    } else {
      this.messageError = false
      let zip_code = this.zipcodeFirst.concat(this.zipcodeLast)
      if (zip_code.length !== 7) {
        this.messageError = true
      } else {
        await SelectStoreService.selectStore(zip_code).then(
          (response: any) => {
            if (response.data.items.length !== 0) {
              localStorage.setItem('items', JSON.stringify(response.data.items))
              this.$router.push({
                name: 'select-store-success'
              })
            } else {
              this.$router.push({ name: 'select-store-error' })
            }
          },
          (error: any) => {
            if (error.response.status === 429) {
              localStorage.clear()
              this.$bvModal.show(`too-many-request`)
            }
          }
        )
      }
    }
  }
}
